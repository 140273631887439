/* eslint-disable */
// GENERATED FILE - DO NOT MODIFY - CHANGES WILL BE OVERWRITTEN
const routes = (function (urlFor) {

    urlFor.routes = {
"static":[{func: ({filename, ...$Qs}) => [`/static/${urlFor.converters.path(filename)}`,$Qs], args: new Set(["filename"])}],
"base.home_page":[{func: ($Qs) => [`/`,$Qs]}],
"base.dashboard":[{func: ($Qs) => [`/dashboard`,$Qs]}],
"relay.relay_route":[{func: ({component, ...$Qs}) => [`/app/${urlFor.converters.relay_component(component)}`,$Qs], args: new Set(["component"])}],
"relay.relay_admin_route":[{func: ({component, ...$Qs}) => [`/app/admin/${urlFor.converters.relay_component(component)}`,$Qs], args: new Set(["component"])},{func: ({component, id, ...$Qs}) => [`/app/admin/${urlFor.converters.relay_subcomponent(component)}/${urlFor.converters.string(id)}`,$Qs], args: new Set(["component", "id"])}],
"relay.agencies":[{func: ($Qs) => [`/app/admin/agencies`,$Qs]}],
"relay.email_newsletter":[{func: ($Qs) => [`/app/admin/email_newsletter`,$Qs]}],
"user.login":[{func: ($Qs) => [`/user/login/`,$Qs]}],
"user.logout":[{func: ($Qs) => [`/user/logout`,$Qs]}],
"user.change_password":[{func: ($Qs) => [`/user/change_password`,$Qs]}],
"user.update_profile":[{func: ($Qs) => [`/user/update_profile`,$Qs]}],
"user.register":[{func: ($Qs) => [`/user/register/`,$Qs]}],
"match.all":[{func: ($Qs) => [`/match/`,$Qs]}],
"match.centimorgan_histogram":[{func: ($Qs) => [`/match/centimorgan_histogram`,$Qs]}],
"match.cluster_heatmap":[{func: ({identity_hashid, ...$Qs}) => [`/match/${urlFor.converters.string(identity_hashid)}/cluster_heatmap`,$Qs], args: new Set(["identity_hashid"])}],
"match.duplicates":[{func: ($Qs) => [`/match/duplicates/`,$Qs]}],
"match.search":[{func: ($Qs) => [`/match/search`,$Qs]}],
"match.segments":[{func: ({identity_hashid, relative_hashid_seq, ...$Qs}) => [`/match/${urlFor.converters.string(identity_hashid)}/${urlFor.converters.string(relative_hashid_seq)}/segments`,$Qs], args: new Set(["identity_hashid", "relative_hashid_seq"])}],
"match.segments_tsv":[{func: ({identity_hashid, relative_hashid_seq, ...$Qs}) => [`/match/${urlFor.converters.string(identity_hashid)}/${urlFor.converters.string(relative_hashid_seq)}/segments.tsv`,$Qs], args: new Set(["identity_hashid", "relative_hashid_seq"])}],
"match.single":[{func: ({identifier, ...$Qs}) => [`/match/${urlFor.converters.string(identifier)}`,$Qs], args: new Set(["identifier"])}],
"identity.identity_detail":[{func: ({identity_hashid, ...$Qs}) => [`/identity/details/${urlFor.converters.string(identity_hashid)}`,$Qs], args: new Set(["identity_hashid"])}],
"identity.report_biosex":[{func: ({identity_hashid, ...$Qs}) => [`/identity/reports/${urlFor.converters.string(identity_hashid)}/biological_sex`,$Qs], args: new Set(["identity_hashid"])}],
"support.faq":[{func: ($Qs) => [`/faq`,$Qs]}],
"support.terms_of_use":[{func: ({version, ...$Qs}) => [`/terms/${urlFor.converters.string(version)}`,$Qs], args: new Set(["version"])},{func: ($Qs) => [`/terms`,$Qs], defaults: {"version": "may-15-2024_v1.1"}}],
"support.cookie_policy":[{func: ({version, ...$Qs}) => [`/cookies/${urlFor.converters.string(version)}`,$Qs], args: new Set(["version"])},{func: ($Qs) => [`/cookies`,$Qs], defaults: {"version": "november-8-2021_v1.0"}}],
"support.privacy":[{func: ({version, ...$Qs}) => [`/privacy/${urlFor.converters.string(version)}`,$Qs], args: new Set(["version"])},{func: ($Qs) => [`/privacy`,$Qs], defaults: {"version": "may-15-2024_v1.1"}}],
"admin.dashboard":[{func: ($Qs) => [`/admin/dashboard`,$Qs]}],
"admin.swab_kit_label_print":[{func: ({date, ...$Qs}) => [`/admin/swab_kits/labels_${urlFor.converters.iso8601(date)}.pdf`,$Qs], args: new Set(["date"])}],
"admin.user_detail":[{func: ({user_hashid, ...$Qs}) => [`/admin/users/${urlFor.converters.string(user_hashid)}`,$Qs], args: new Set(["user_hashid"])}],
"tree.tree_detail":[{func: ({tree_hashid, ...$Qs}) => [`/tree/details/${urlFor.converters.string(tree_hashid)}`,$Qs], args: new Set(["tree_hashid"])}],
"woodpecker.kit":[{func: ({kit_num, ...$Qs}) => [`/woodpecker/kit/${urlFor.converters.string(kit_num)}`,$Qs], args: new Set(["kit_num"])}],
"woodpecker.match":[{func: ({kit1, kit2, ...$Qs}) => [`/woodpecker/match/${urlFor.converters.string(kit1)}/${urlFor.converters.string(kit2)}`,$Qs], args: new Set(["kit1", "kit2"])}],
"woodpecker.search":[{func: ($Qs) => [`/woodpecker/`,$Qs]}],
"articles.contribute":[{func: ({slug, ...$Qs}) => [`/articles/${urlFor.converters.string(slug)}/contribute`,$Qs], args: new Set(["slug"])}],
"articles.set_subscription_amount":[{func: ($Qs) => [`/articles/subscribe_iframe`,$Qs], defaults: {"for_iframe": true}},{func: ($Qs) => [`/articles/subscribe`,$Qs], defaults: {"for_iframe": false}}],
"articles.subscribe":[{func: ($Qs) => [`/articles/subscription/create_customer`,$Qs]}],
"articles.cancel_subscription":[{func: ($Qs) => [`/articles/subscription/cancel`,$Qs]}],
"articles.billing":[{func: ({slug, ...$Qs}) => [`/articles/${urlFor.converters.string(slug)}/billing/`,$Qs], args: new Set(["slug"])}],
"articles.all_articles":[{func: ({article_status, ...$Qs}) => [`/articles/${urlFor.converters.article_filter(article_status)}_cases/`,$Qs], args: new Set(["article_status"])},{func: ($Qs) => [`/articles/`,$Qs], defaults: {"article_status": "any"}}],
"articles.successful_contribution":[{func: ({slug, ...$Qs}) => [`/articles/${urlFor.converters.string(slug)}/thanks`,$Qs], args: new Set(["slug"])}],
"articles.content_dashboard_edit_case":[{func: ({slug, ...$Qs}) => [`/articles/edit/${urlFor.converters.string(slug)}`,$Qs], args: new Set(["slug"])}],
"articles.content_dashboard":[{func: ($Qs) => [`/articles/content_management`,$Qs]}],
"articles.embed_click":[{func: ({gid, ...$Qs}) => [`/articles/embed/${urlFor.converters.string(gid)}/contribute`,$Qs], args: new Set(["gid"]), defaults: {"destination": "contribute"}},{func: ({gid, ...$Qs}) => [`/articles/embed/${urlFor.converters.string(gid)}/permalink`,$Qs], args: new Set(["gid"]), defaults: {"destination": "permalink"}}],
"articles.iframe_embed":[{func: ({slug, ...$Qs}) => [`/articles/iframe-embed-wide/${urlFor.converters.string(slug)}/`,$Qs], args: new Set(["slug"]), defaults: {"wide_variant": true}},{func: ({slug, ...$Qs}) => [`/articles/iframe-embed/${urlFor.converters.string(slug)}/`,$Qs], args: new Set(["slug"]), defaults: {"wide_variant": false}}],
"articles.export_newsletter_list":[{func: ($Qs) => [`/articles/export_newsletter_list`,$Qs]}],
"articles.beta_news_feed":[{func: ($Qs) => [`/articles/feed`,$Qs]}],
"geos.states":[{func: ($Qs) => [`/cases/united_states_of_america/`,$Qs]},{func: ($Qs) => [`/cases/us/`,$Qs]}],
"geos.provinces":[{func: ($Qs) => [`/cases/canada/`,$Qs]},{func: ($Qs) => [`/cases/ca/`,$Qs]}],
"geos.state":[{func: ({state, ...$Qs}) => [`/cases/us/${urlFor.converters.us_state(state)}/`,$Qs], args: new Set(["state"])}],
"geos.province":[{func: ({province, ...$Qs}) => [`/cases/ca/${urlFor.converters.ca_province(province)}/`,$Qs], args: new Set(["province"])}]
};
urlFor.enums.article_statuses = {"ANY": "any", "NOT_YET_FUNDED": "pending", "FUNDED": "in_progress", "SOLVED": "solved"};
urlFor.converters.article_filter = (a)=>{
    a=urlFor.enums.article_statuses[a.toUpperCase()];
    if(a){ return a; }
    else{
        throw new Error('Invalid article_filter')
    }
};
    

const underscore = (s)=>s.replace(/([a-z\d])([A-Z])/g, '$1_$2').replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1_$2').toLowerCase()
urlFor.converters.relay_component = underscore;
urlFor.converters.relay_subcomponent = (s)=>{
    const underscored = underscore(s);
    // nieve depluralize
    return underscored.endsWith("s") ? underscored.substr(0, underscored.length-1) : underscored;
};
    

urlFor.converters.path = (a)=>a;
    

urlFor.enums.us_enum = {"CA": "california", "AL": "alabama", "ME": "maine", "NH": "new_hampshire", "MA": "massachusetts", "CT": "connecticut", "HI": "hawaii", "AK": "alaska", "FL": "florida", "AZ": "arizona", "OR": "oregon", "UT": "utah", "MI": "michigan", "IL": "illinois", "ND": "north_dakota", "SD": "south_dakota", "MT": "montana", "WY": "wyoming", "ID": "idaho", "WA": "washington", "NV": "nevada", "CO": "colorado", "VA": "virginia", "WV": "west_virginia", "NY": "new_york", "RI": "rhode_island", "MD": "maryland", "DE": "delaware", "OH": "ohio", "PA": "pennsylvania", "NJ": "new_jersey", "IN": "indiana", "GA": "georgia", "TX": "texas", "NC": "north_carolina", "SC": "south_carolina", "MS": "mississippi", "TN": "tennessee", "NM": "new_mexico", "MN": "minnesota", "WI": "wisconsin", "IA": "iowa", "NE": "nebraska", "KS": "kansas", "MO": "missouri", "LA": "louisiana", "KY": "kentucky", "AR": "arkansas", "OK": "oklahoma", "VT": "vermont"};
urlFor.enums.ca_enum = {"QC": "quebec", "ON": "ontario", "MB": "manitoba", "AB": "alberta", "NS": "nova_scotia", "NB": "new_brunswick", "BC": "british_columbia", "PE": "prince_edward_island", "SK": "saskatchewan", "NL": "newfoundland_and_labrador", "NT": "northwest_territories", "YT": "yukon", "NU": "nunavut"};

const usErrmsg = 'Invalid US state';
const caErrmsg = 'Invalid Canadian province';

const makeStateConverter = (map, err) => {
    return (a)=>{
        // if its already valid pass it through
        if (Object.values(map).includes(a)){
            return a;
        }
        // lookup the slug and return it
        a=map[a.toUpperCase()];
        if(a){ return a; }
        else{throw new Error(err)}
    };
};
urlFor.converters.us_state = makeStateConverter(urlFor.enums.us_enum, usErrmsg);
urlFor.converters.ca_province = makeStateConverter(urlFor.enums.ca_enum, caErrmsg);


    return urlFor.routes;
})(window.urlFor);

export default routes;
/* eslint-enable */
